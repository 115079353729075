// This file was autogenerated. Please do not change.
export interface ISpotTradesRequest {
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementName: string;
    symbol: string;
}

export default class SpotTradesRequest {
    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementName: string;

    /**
     * Description: Placement (CEx) name
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _symbol: string;

    /**
     * Description: Spot asset pair symbol
     * Example: BTC/USDT
     */
    get symbol(): string {
        return this._symbol;
    }

    constructor(props: ISpotTradesRequest) {
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        this._placementName = props.placementName.trim();
        this._symbol = props.symbol.trim();
    }

    serialize(): ISpotTradesRequest {
        const data: ISpotTradesRequest = {
            placementName: this._placementName,
            symbol: this._symbol,
        };
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        return data;
    }

    toJSON(): ISpotTradesRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
