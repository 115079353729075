
/* eslint-disable no-undef */

import Vue from 'vue';

import { channelsCallbacks, ISubscription, subscribe, unsubscribeChannel } from 'Store/v2/PublicSocketData';
import MarketDataApi from 'Apis/MarketData';
import OrderBookRequest from 'Entities/publicPresenter/OrderBookRequest';
import SpotTradesRequest from 'Entities/publicPresenter/SpotTradesRequest';

export interface IAskAndBidsRecord {
    price: number;
    volume: number;
    total: number;
}

interface LadderUi {
    bidsCount: number,
    asksCount: number,

    activeAggregationIndex: number,
    isCumulative: boolean,
    aggregationLevels: string[],
}

interface IInitPayload {
    placement?: string,
    pair?: string,
}

export interface IAggregationRecord {
    aggregation: string,
    weight: number,
}

interface Data {
    asks: IAskAndBidsRecord[];
    bids: IAskAndBidsRecord[];
    previousPlacement: string;
    previousPair: string;
    subscriptionIndex: ISubscription | null;
    tradesSubscriptionIndex: ISubscription | null;
    currentPlacement: string;
    ui: LadderUi;
    marketPrice: number;
    lastMarketPrice: number;
}

export default Vue.extend<Data, any, any>({
    data() {
        return {
            asks: [],
            bids: [],
            previousPlacement: '',
            previousPair: '',
            subscriptionIndex: null,
            tradesSubscriptionIndex: null,
            currentPlacement: '',
            ui: {
                bidsCount: 10,
                asksCount: 10,

                activeAggregationIndex: 0,
                isCumulative: true,
                aggregationLevels: [],
            },
            marketPrice: 0,
            lastMarketPrice: 0,
        };
    },
    methods: {
        async removeSnapshot(placement: string, pair: string) {
            if (!channelsCallbacks[`orderbooks:${this.$store.state.Placements.placementNamesToPlacementTags.get(placement.toUpperCase())}-${pair}`]) {
                if (this.subscriptionIndex?.uid) {
                    try {
                        await removeSnapshot(this.subscriptionIndex.uid, placement, pair);
                    } catch {
                        // WASM error
                    }
                }
            }
        },
        SET_SUBSCRIPTION_INDEX(index: ISubscription) {
            this.subscriptionIndex = index;
        },
        async INIT(placement: string, pair: string) {
            this.currentPlacement = placement;
            this.asks = [];
            this.bids = [];
            this.ui.isCumulative = true;
            this.ui.aggregationLevels = [];
            this.ui.activeAggregationIndex = 0;

            try {
                if (this.subscriptionIndex?.uid) {
                    const params = JSON.parse(
                        await getSnapshotParams(this.subscriptionIndex.uid, placement, pair),
                    );
                    this.ui.aggregationLevels = params.availableGroupings;
                }
            } catch {
                // code crushed
            }
        },
        async UPDATE_ORDER_BOOK() {
            try {
                if (this.subscriptionIndex?.uid) {
                    try {
                        const { asks, bids } = JSON.parse(
                            await getSnapshot(
                                this.subscriptionIndex.uid,
                                this.placement,
                                this.pair,
                                JSON.stringify({
                                    grouping: this.ui.aggregationLevels.length ? this.ui.aggregationLevels[this.ui.activeAggregationIndex] : '0.0000001',
                                    cumulativeVolume: this.ui.isCumulative,
                                    maxLevelsCount: 10,
                                }),
                            ),
                        );
                        this.asks = asks;
                        this.bids = bids;
                    } catch {
                        this.asks = [];
                        this.bids = [];
                    }
                }
            } catch {
                // code crushed
            }
        },
        async init(placementAndPairData: IInitPayload) {
            const { placement, pair } = placementAndPairData;

            if (!placement || !pair) {
                return;
            }

            await this.INIT(placement, pair);
            if (this.subscriptionIndex !== null) {
                await this.$store.dispatch(unsubscribeChannel(this.subscriptionIndex));
                try {
                    await this.removeSnapshot(this.previousPlacement, this.previousPair);
                } catch {
                    // WASM error
                }
                this.subscriptionIndex = null;
            }
            this.previousPlacement = placement;
            this.previousPair = pair;

            const index = await this.$store.dispatch(subscribe({
                channel: `orderbooks:${this.$store.state.Placements.placementNamesToPlacementTags.get(placement.toUpperCase())}-${pair}`,
                callbacks: {
                    subscribe: async () => {
                        try {
                            setTimeout(async () => {
                                const { data: res } = await MarketDataApi.publicGetOrderBook(new OrderBookRequest({
                                    placementName: placement,
                                    symbol: pair,
                                }));
                                if (typeof res !== 'number') {
                                    if (this.subscriptionIndex?.uid) {
                                        try {
                                            await setSnapshot(
                                                this.subscriptionIndex.uid,
                                                placement,
                                                pair,
                                                JSON.stringify(res.serialize()),
                                            );
                                        } catch {
                                            // WASM error
                                        }
                                        try {
                                            const params = JSON.parse(await getSnapshotParams(this.subscriptionIndex.uid, placement, pair));
                                            this.ui.aggregationLevels = params.availableGroupings;
                                        } catch {
                                            // WASM error
                                        }

                                        await this.UPDATE_ORDER_BOOK();
                                    }
                                }
                            }, 5000);
                        } catch {
                            // code crushed because of network error
                        }
                    },
                    publish: async (data: any) => {
                        try {
                            if (this.subscriptionIndex?.uid) {
                                try {
                                    await applyDelta(
                                        this.subscriptionIndex.uid,
                                        placement,
                                        pair,
                                        JSON.stringify(data),
                                    );
                                } catch {
                                    // WASM error
                                }
                                if (data.type === 'snapshot') {
                                    try {
                                        const params = JSON.parse(await getSnapshotParams(this.subscriptionIndex.uid, placement, pair));
                                        this.ui.aggregationLevels = params.availableGroupings;
                                    } catch {
                                        // WASM error
                                    }
                                }
                                await this.UPDATE_ORDER_BOOK();
                            }
                        } catch (error) {
                            console.log('error in apply delta method in orderbook', error);
                        }
                    },
                },
            }));
            this.SET_SUBSCRIPTION_INDEX(index, true);
            await this.subscribeTrades({ placement, pair });
        },
        async subscribeTrades({ placement, pair }) {
            if (this.tradesSubscriptionIndex !== null) {
                await this.$store.dispatch(unsubscribeChannel(this.tradesSubscriptionIndex));
            }
            this.marketPrice = 0;
            this.lastMarketPrice = 0;

            this.tradesSubscriptionIndex = await this.$store.dispatch(subscribe({
                channel: `trades:${this.$store.state.Placements.placementNamesToPlacementTags.get(placement.toUpperCase())}-${pair}`,
                callbacks: {
                    subscribe: async () => {
                        const { data: res } = await MarketDataApi.getPublicSpotTrades(new SpotTradesRequest({ placementName: placement, symbol: pair }));
                        if (Array.isArray(res)) {
                            this.lastMarketPrice = this.marketPrice;
                            this.marketPrice = res.length > 0 ? (res.sort(({ timestamp: aTimestamp }, { timestamp: bTimestamp }) => {
                                if (aTimestamp! > bTimestamp!) {
                                    return -1;
                                }
                                if (bTimestamp! > aTimestamp!) {
                                    return 1;
                                }
                                return 1;
                            })[0].price ?? 0) : 0;
                        }
                    },
                    publish: ({ data }: { data }) => {
                        if (data.type === 'snapshot') {
                            this.lastMarketPrice = this.marketPrice;
                            this.marketPrice = data.trades.sort(({ timestamp: aTimestamp }, { timestamp: bTimestamp }) => {
                                if (aTimestamp! > bTimestamp!) {
                                    return -1;
                                }
                                if (bTimestamp! > aTimestamp!) {
                                    return 1;
                                }
                                return 1;
                            })[0].price!;
                        } else {
                            this.lastMarketPrice = this.marketPrice;
                            this.marketPrice = data.trades[data.trades.length - 1].price;
                        }
                    },
                },
            }));
        },
    },
});
