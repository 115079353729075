// This file was autogenerated. Please do not change.
export interface IOrderCostRequest {
    accountId: string;
    bestAsk?: string;
    bestBid?: string;
    contractSymbol: string;
    leverage: number;
    placementTag: string;
    positionSide: string;
    price?: string;
    quantity: string;
    side: string;
}

export default class OrderCostRequest {
    readonly _accountId: string;

    /**
     * Example: A00000001
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _bestAsk: number | undefined;

    /**
     * Example: 3000
     */
    get bestAsk(): number | undefined {
        return this._bestAsk;
    }

    readonly _bestBid: number | undefined;

    /**
     * Example: 3000
     */
    get bestBid(): number | undefined {
        return this._bestBid;
    }

    readonly _contractSymbol: string;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string {
        return this._contractSymbol;
    }

    readonly _leverage: number;

    /**
     * Example: 5
     */
    get leverage(): number {
        return this._leverage;
    }

    readonly _placementTag: string;

    /**
     * Example: BYBIT_FUTURES
     */
    get placementTag(): string {
        return this._placementTag;
    }

    readonly _positionSide: string;

    /**
     * Example: SHORT
     */
    get positionSide(): string {
        return this._positionSide;
    }

    readonly _price: number | undefined;

    /**
     * Example: 3000
     */
    get price(): number | undefined {
        return this._price;
    }

    readonly _quantity: number;

    /**
     * Example: 0.1
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _side: string;

    /**
     * Example: BUY
     */
    get side(): string {
        return this._side;
    }

    constructor(props: IOrderCostRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.bestAsk === 'string') {
            this._bestAsk = Number(props.bestAsk);
        }
        if (typeof props.bestBid === 'string') {
            this._bestBid = Number(props.bestBid);
        }
        this._contractSymbol = props.contractSymbol.trim();
        this._leverage = props.leverage;
        this._placementTag = props.placementTag.trim();
        this._positionSide = props.positionSide.trim();
        if (typeof props.price === 'string') {
            this._price = Number(props.price);
        }
        this._quantity = Number(props.quantity);
        this._side = props.side.trim();
    }

    serialize(): IOrderCostRequest {
        const data: IOrderCostRequest = {
            accountId: this._accountId,
            contractSymbol: this._contractSymbol,
            leverage: this._leverage,
            placementTag: this._placementTag,
            positionSide: this._positionSide,
            quantity: String(this._quantity),
            side: this._side,
        };
        if (typeof this._bestAsk !== 'undefined') {
            data.bestAsk = String(this._bestAsk);
        }
        if (typeof this._bestBid !== 'undefined') {
            data.bestBid = String(this._bestBid);
        }
        if (typeof this._price !== 'undefined') {
            data.price = String(this._price);
        }
        return data;
    }

    toJSON(): IOrderCostRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            bestAsk: true,
            bestBid: true,
            contractSymbol: typeof this._contractSymbol === 'string' && !!this._contractSymbol.trim(),
            leverage: typeof this._leverage === 'number',
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
            positionSide: typeof this._positionSide === 'string' && !!this._positionSide.trim(),
            price: true,
            quantity: true,
            side: typeof this._side === 'string' && !!this._side.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
