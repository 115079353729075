
import Vue from 'vue';

import Trading from '../Trading.vue';

export default Vue.extend<any, any, any, any>({
    components: { Trading },
    props: {
        placement: {
            type: String,
            default: '',
        },
        pair: {
            type: String,
            default: '',
        },
        uniqueId: {
            type: String,
            required: true,
        },
    },
});
