<template>
    <div :class="theme.tradehistory.tradehistory">
        <div :class="[theme.tradehistory.grid, theme.tradehistory.tradehistoryHeader, theme.table.table]">
            <span :class="[theme.table.headerText, theme.tradehistory.textLeft]">Act</span>
            <span :class="[theme.table.headerText, theme.tradehistory.textCenter]">Trade size</span>
            <span :class="[theme.table.headerText, theme.tradehistory.textCenter]">Price</span>
            <span :class="[theme.table.headerText, theme.tradehistory.textRight]">Time</span>
        </div>
        <div v-for="(record, index) in trades" :key="`tradeRow${index}`" :class="[theme.tradehistory.grid, theme.tradehistory.tradehistoryRow]">
            <span :class="[theme.tradehistory.actText, theme.tradehistory.textLeft, { [theme.tradehistory.red]: record.side === 'sell', [theme.tradehistory.green]: record.side === 'buy' }]">
                {{ record.side }}
            </span>
            <span :class="[theme.tradehistory.valueText, theme.tradehistory.textCenter]">
                {{ tradeSize(record) }}
            </span>
            <span :class="[theme.tradehistory.priceText, theme.tradehistory.textCenter]">
                <AssetValue
                    :value="tradePrice(record)"
                    :asset="currentAssetPair ? currentAssetPair.split('/')[1] : ''"
                    :need-to-round="false"
                    :value-class="theme.tradehistory.priceText"
                    is-aligned-center
                />
            </span>
            <span :class="[theme.tradehistory.timeText, theme.tradehistory.textRight]">
                {{ formDate(record.timestamp) }}
            </span>
        </div>
    </div>
</template>

<script>
import { TradeSides } from 'Models/tradeHistory';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';

export default {
    name: 'TradeHistoryTable',
    components: {
        AssetValue,
    },
    props: {
        trades: {
            type: Array,
            required: true,
        },
        currentPlacement: {
            type: String,
            required: true,
        },
        currentAssetPair: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            TradeSides,
            stringToFixedDigits,
            theme,
        };
    },
    methods: {
        formDate(timestamp) {
            const date = new Date(timestamp);
            return `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
            }:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
            }:${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;
        },
        tradePrice(record) {
            return record.price.toFixed(this.pricePrecision).noExponents().getSeparatedDigits();
        },
        tradeSize(record) {
            return record.size.toFixed(this.quantityPrecision).noExponents().getSeparatedDigits();
        },
    },
    computed: {
        currentAssetPairData() {
            try {
                return this.$store.state.AssetPairs.workspaceSpotAssetPairs
                    .get(this.currentPlacement.toUpperCase())
                    .get(this.currentAssetPair.toUpperCase());
            } catch {
                return undefined;
            }
        },
        pricePrecision() {
            if (!this.currentAssetPairData) {
                return 8;
            }
            return this.currentAssetPairData.placementPrecisionPrice;
        },
        quantityPrecision() {
            if (!this.currentAssetPairData) {
                return 8;
            }
            return this.currentAssetPairData.placementPrecisionQuantity;
        },
    },
};
</script>
