export default {
    inject: {
        WorkspaceModule: {
            default: null,
        },
    },
    computed: {
        isWorkspaceModule() {
            return !!this.WorkspaceModule;
        },
        workspaceModuleConfig() {
            return this.WorkspaceModule?.config || {};
        },

        workspaceModuleConfigPlacement() {
            return this.workspaceModuleConfig.placement;
        },
        workspaceModuleConfigPair() {
            return this.workspaceModuleConfig.pair;
        },
    } as any,
} as any;
