
import Vue from 'vue';

import { darkOverrides, lightOverrides, THEMES_NAMES } from 'Config/tradingView';
import SettingsApi from 'Apis/Settings';
import SavePairChartDataRequest from 'Entities/userSettings/SavePairChartDataRequest';
import GetPairChartDataRequest from 'Entities/userSettings/GetPairChartDataRequest';
import { ICandle } from 'Entities/candlesPresenter/Candle';
import Trade from 'Entities/publicPresenter/Trade';
import CandlesRequest from 'Entities/candlesPresenter/CandlesRequest';
import MarketDataApi from 'Apis/MarketData';
import { ISubscription, subscribe, unsubscribeChannel } from 'Store/v2/PublicSocketData';
import SpotTradesRequest from 'Entities/publicPresenter/SpotTradesRequest';
import SpotAssetPair from 'Entities/publicPresenter/SpotAssetPair';

import { IChartingLibraryWidget } from '../../../../../public/charting_library/charting_library.d';
import { widget as Widget } from '../../../../../public/charting_library';

export interface IDatafeed {
    onReady: (callback) => void,
    getServerTime: (callback) => Promise<void>,
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => void,
    getBars: (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => Promise<void>,
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => Promise<void>,
    unsubscribeBars: () => boolean,
}

interface Data {
    tvWidget: null | IChartingLibraryWidget,
    lastResolution: string,
    datafeed: IDatafeed | null,
    lastCandleData: ICandle,
    currentResolution: number,
    subscriptionIndex: ISubscription | null,
    tradesBookSubscriptionIndex: null | ISubscription;
}

interface Methods {
    buildWidget: (isFirstBuild?: boolean) => void,
    UPDATE_LAST_CANDLE_DATA: any,
    SET_DATAFEED: any,
    SET_SUBSCRIPTION_INDEX: any,
    CLEAR_LAST_CANDLE_DATA: any,
    initDatafeed: any,
    saveChartData: () => void;
}

interface ILoadingScreen {
    backgroundColor: string,
}

interface Computed {
    theme: string,
    loadingScreen: ILoadingScreen,
    overrides: typeof darkOverrides | typeof lightOverrides,
    currentAssetPairData: SpotAssetPair | undefined;
}

export default Vue.extend<Data, Methods, Computed, any>({
    name: 'TVChartContainer',
    data() {
        return {
            tvWidget: null,
            lastResolution: '60',
            datafeed: null,
            lastCandleData: {},
            currentResolution: 0,
            subscriptionIndex: null,
            tradesBookSubscriptionIndex: null,
            chartState: null,
        };
    },
    props: {
        resolution: {
            type: String,
            required: true,
        },
        placement: {
            type: String,
        },
        pair: {
            type: String,
        },
    },
    computed: {
        theme() {
            return this.$store.getters.isThemeDark ? THEMES_NAMES.DARK : THEMES_NAMES.LIGHT;
        },
        loadingScreen() {
            return this.theme === THEMES_NAMES.DARK ? {
                backgroundColor: '#2C2C36',
            } : {
                backgroundColor: 'white',
            };
        },
        overrides() {
            return this.theme === THEMES_NAMES.DARK ? darkOverrides : lightOverrides;
        },
        currentAssetPairData() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs
                .get(this.placement?.toUpperCase() ?? '')
                ?.get(this.pair?.toUpperCase() ?? '');
        },
    },
    methods: {
        UPDATE_LAST_CANDLE_DATA(trade: Trade | undefined, totalVolume: number) {
            if (trade === undefined) {
                return;
            }

            const lastTrade = trade;
            const fullTimestamp = lastTrade.timestamp;
            if (fullTimestamp! >= this.lastCandleData.time! && fullTimestamp! < this.lastCandleData.time! + this.currentResolution * 60 * 1000) {
                this.lastCandleData = {
                    ...this.lastCandleData,
                    close: lastTrade.price,
                    low: this.lastCandleData.low! > lastTrade.price! ? lastTrade.price : this.lastCandleData.low,
                    high: this.lastCandleData.high! < lastTrade.price! ? lastTrade.price : this.lastCandleData.high,
                    volume: this.lastCandleData.volume! + totalVolume,
                };
            } else {
                this.lastCandleData = {
                    open: lastTrade.price,
                    close: lastTrade.price,
                    low: lastTrade.price,
                    high: lastTrade.price,
                    volume: totalVolume,
                    time: this.lastCandleData.time! + this.currentResolution * 60 * 1000,
                };
            }
        },

        SET_DATAFEED(datafeed: IDatafeed) {
            this.datafeed = datafeed;
        },

        SET_SUBSCRIPTION_INDEX(index: ISubscription) {
            this.subscriptionIndex = index;
        },

        CLEAR_LAST_CANDLE_DATA() {
            this.lastCandleData = {};
        },

        initDatafeed() {
            const datafeed = {
                onReady: (callback) => {
                    setTimeout(() => {
                        callback({
                            supports_marks: false,
                            supports_time: true,
                            supports_search: true,
                            supports_group_request: false,
                            supported_resolutions: [
                                '1',
                                '3',
                                '5',
                                '15',
                                '30',
                                '60',
                                '120',
                                '240',
                                '360',
                                '480',
                                '720',
                                '1440',
                                '10080',
                                '20160',
                                '40320',
                            ],
                        });
                    }, 0);
                },
                resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
                    setTimeout(() => {
                        onSymbolResolvedCallback({
                            description: symbolName,
                            exchange: `${this.placement}`,
                            has_intraday: true,
                            minmov: 1,
                            name: symbolName,
                            pointvalue: 1,
                            pricescale: 10 ** (this.currentAssetPairData?.placementPrecisionPrice ?? 8),
                            session: '24x7',
                            supported_resolutions: [
                                '1',
                                '3',
                                '5',
                                '15',
                                '30',
                                '60',
                                '120',
                                '240',
                                '360',
                                '480',
                                '720',
                                '1440',
                                '10080',
                                '20160',
                                '40320',
                            ],
                            ticker: symbolName,
                            timezone: 'Etc/UTC',
                            type: 'crypto',
                            volume_precision: 8,
                        });
                    }, 0);
                },
                getServerTime: async (callback) => {
                    const { data: res } = await MarketDataApi.getTime();
                    callback(res.time);
                },
                getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback) => {
                    resolution = Number(resolution);
                    if (!symbolInfo.exchange) {
                        return;
                    }
                    const { data: res } = await MarketDataApi.getCandles(new CandlesRequest({
                        from: periodParams.from,
                        to: periodParams.to,
                        resolution,
                        placementName: symbolInfo.exchange,
                        symbol: symbolInfo.ticker,
                    }));
                    if (symbolInfo.ticker === this.pair) {
                        if (res.length) {
                            const lastCandle = res[res.length - 1];
                            if (!this.lastCandleData.time || lastCandle.time! > this.lastCandleData.time) {
                                this.lastCandleData = {
                                    low: lastCandle.low,
                                    high: lastCandle.high,
                                    open: lastCandle.open,
                                    close: lastCandle.close,
                                    volume: lastCandle.volume,
                                    time: lastCandle.time,
                                };
                            }
                        }
                        this.currentResolution = resolution;
                        onHistoryCallback(res, { noData: res.length === 0 });
                    }
                },
                subscribeBars: async (_: unknown, __: unknown, onRealtimeCallback: (data: any) => void) => {
                    this.tradesBookSubscriptionIndex = await this.$store.dispatch(subscribe({
                        channel: `trades:${this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement?.toUpperCase())}-${this.pair}`,
                        callbacks: {
                            subscribe: async () => {
                                const { data: res } = await MarketDataApi.getPublicSpotTrades(new SpotTradesRequest({ placementName: this.placement, symbol: this.pair }));
                                if (Array.isArray(res)) {
                                    const marketPrice = res.sort(({ timestamp: aTimestamp }, { timestamp: bTimestamp }) => {
                                        if (aTimestamp! > bTimestamp!) {
                                            return -1;
                                        }
                                        if (bTimestamp! > aTimestamp!) {
                                            return 1;
                                        }
                                        return 1;
                                    })[0];
                                    this.UPDATE_LAST_CANDLE_DATA(marketPrice, marketPrice?.size ?? 0);
                                    onRealtimeCallback(this.lastCandleData);
                                }
                            },
                            publish: ({ data }: { data }) => {
                                if (data.type === 'snapshot') {
                                    const marketPrice = data.trades.sort(({ timestamp: aTimestamp }, { timestamp: bTimestamp }) => {
                                        if (aTimestamp! > bTimestamp!) {
                                            return -1;
                                        }
                                        if (bTimestamp! > aTimestamp!) {
                                            return 1;
                                        }
                                        return 1;
                                    })[0];
                                    this.UPDATE_LAST_CANDLE_DATA(marketPrice, marketPrice?.size ?? 0);
                                    onRealtimeCallback(this.lastCandleData);
                                } else if (data.trades[data.trades.length - 1].symbol === this.pair) {
                                    const marketPrice = data.trades[data.trades.length - 1];
                                    const totalButchVolume = data.trades.reduce((accum, { size }) => accum + Number(size), 0);
                                    this.UPDATE_LAST_CANDLE_DATA(marketPrice, totalButchVolume);
                                    onRealtimeCallback(this.lastCandleData);
                                }
                            },
                        },
                    }));
                },
                unsubscribeBars: async () => {
                    if (this.tradesBookSubscriptionIndex !== null) {
                        await this.$store.dispatch(unsubscribeChannel(this.tradesBookSubscriptionIndex));
                    }
                },
            };
            this.SET_DATAFEED(datafeed);
        },

        async buildWidget(isFirstDownload = true) {
            const container = this.$refs.chartContainer;
            if (this.tvWidget) {
                (container as HTMLDivElement).innerHTML = '';
                this.tvWidget = null;
            }
            if (this.pair && this.placement) {
                const widgetOptions: any = {
                    debug: false,
                    symbol: this.pair,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as any,
                    theme: this.theme,
                    overrides: this.overrides,
                    locale: 'en',
                    supports_time: true,
                    datafeed: this.datafeed,
                    interval: this.resolution,
                    resolution: '1',
                    loading_screen: this.loadingScreen,
                    container,
                    library_path: '/charting_library/',
                    disabled_features: [
                        'control_bar',
                        'header_symbol_search',
                        'header_compare',
                        'timeframes_toolbar',
                        'header_resolutions',
                        'use_localstorage_for_settings',
                        'save_chart_properties_to_local_storage',
                    ],
                    enabled_features: [
                        'study_templates',
                        'create_volume_indicator_by_default',
                    ],
                    client_id: '0',
                    user_id: '0',
                    fullscreen: false,
                    autosize: true,
                };
                const tvWidget = new Widget(widgetOptions);
                tvWidget.onChartReady(async () => {
                    try {
                        const { data } = await SettingsApi.getPairChartData(new GetPairChartDataRequest({
                            placementName: this.placement,
                            symbol: this.pair,
                        }));
                        if ((data.chartData as any)?.charts) {
                            tvWidget.load(data.chartData as any);
                            const resolution = tvWidget.chart().resolution();
                            if (resolution !== this.resolution && isFirstDownload) {
                                this.$emit('resolution', resolution);
                            } else if (resolution !== this.resolution && !isFirstDownload) {
                                tvWidget.chart().setResolution(this.resolution);
                            }
                        }
                    } catch {
                        // code crushed because of network error
                    }
                    if (tvWidget) {
                        tvWidget.applyOverrides(this.overrides as any);
                        tvWidget.addCustomCSSFile('/custom-css.css');
                    }
                    this.tvWidget = tvWidget;
                    this.tvWidget!.subscribe('drawing_event', this.saveChartData);
                    this.tvWidget!.subscribe('study_event', this.saveChartData);
                });
            }
        },
        saveChartData() {
            setTimeout(() => {
                if (this.tvWidget) {
                    this.tvWidget.save(async (state) => {
                        try {
                            await SettingsApi.savePairChartData(new SavePairChartDataRequest({
                                placementName: this.placement,
                                symbol: this.pair,
                                chartData: state as any,
                            }));
                        } catch {
                            // code crushed because of network error
                        }
                    });
                }
            }, 200);
        },
    },
    mounted() {
        this.initDatafeed();
        if (this.currentAssetPairData && this.placement && this.pair && this.theme && this.loadingScreen && this.datafeed && this.overrides) {
            this.buildWidget();
        }
    },
    async beforeDestroy() {
        if (this.tvWidget) {
            try {
                this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                this.tvWidget.unsubscribe('study_event', this.saveChartData);

                const container = this.$refs.chartContainer;
                (container as HTMLDivElement).innerHTML = '';
                this.tvWidget = null;
            } catch {
                // ChartingLibrary internal error
            }
        }
        if (this.tradesBookSubscriptionIndex !== null) {
            await this.$store.dispatch(unsubscribeChannel(this.tradesBookSubscriptionIndex));
            this.tradesBookSubscriptionIndex = null;
        }
    },
    watch: {
        async placement(value) {
            if (value) {
                if (this.currentAssetPairData && value && this.pair && this.theme && this.loadingScreen && this.datafeed && this.overrides) {
                    this.CLEAR_LAST_CANDLE_DATA();
                    this.$emit('update');
                    if (this.tvWidget) {
                        this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                        this.tvWidget.unsubscribe('study_event', this.saveChartData);
                    }
                    await this.buildWidget();
                }
            }
        },
        async pair(assetPair) {
            if (assetPair) {
                if (this.currentAssetPairData && this.placement && assetPair && this.theme && this.loadingScreen && this.datafeed && this.overrides) {
                    this.CLEAR_LAST_CANDLE_DATA();
                    this.$emit('update');
                    if (this.tvWidget) {
                        this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                        this.tvWidget.unsubscribe('study_event', this.saveChartData);
                    }
                    await this.buildWidget();
                }
            }
        },
        async currentAssetPairData(value) {
            if (value) {
                if (this.placement && this.pair && this.theme && this.loadingScreen && this.datafeed && this.overrides && value) {
                    this.CLEAR_LAST_CANDLE_DATA();
                    this.$emit('update');
                    if (this.tvWidget) {
                        this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                        this.tvWidget.unsubscribe('study_event', this.saveChartData);
                    }
                    await this.buildWidget();
                }
            }
        },
        theme() {
            if (this.currentAssetPairData && this.placement && this.pair && this.theme && this.loadingScreen && this.datafeed && this.overrides) {
                if (this.tvWidget) {
                    this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                    this.tvWidget.unsubscribe('study_event', this.saveChartData);
                }
                this.buildWidget(false);
            }
        },
        loadingScreen() {
            if (this.currentAssetPairData && this.placement && this.pair && this.theme && this.loadingScreen && this.datafeed && this.overrides) {
                this.$emit('update');
                if (this.tvWidget) {
                    this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                    this.tvWidget.unsubscribe('study_event', this.saveChartData);
                }
                this.buildWidget();
            }
        },
        datafeed() {
            if (this.currentAssetPairData && this.placement && this.pair && this.theme && this.loadingScreen && this.datafeed && this.overrides) {
                this.$emit('update');
                if (this.tvWidget) {
                    this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                    this.tvWidget.unsubscribe('study_event', this.saveChartData);
                }
                this.buildWidget();
            }
        },
        overrides() {
            if (this.currentAssetPairData && this.placement && this.pair && this.theme && this.loadingScreen && this.datafeed && this.overrides) {
                this.$emit('update');
                if (this.tvWidget) {
                    this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                    this.tvWidget.unsubscribe('study_event', this.saveChartData);
                }
                this.buildWidget();
            }
        },
        resolution(value) {
            if (value === this.lastResolution) {
                return;
            }

            if (this.tvWidget) {
                this.tvWidget.unsubscribe('drawing_event', this.saveChartData);
                this.tvWidget.unsubscribe('study_event', this.saveChartData);
            }
            this.buildWidget(false);
            this.lastResolution = value;
        },
    },
});
