// This file was autogenerated. Please do not change.
export interface IGetPairChartDataRequest {
    placementName: string;
    symbol: string;
}

export default class GetPairChartDataRequest {
    readonly _placementName: string;

    get placementName(): string {
        return this._placementName;
    }

    readonly _symbol: string;

    get symbol(): string {
        return this._symbol;
    }

    constructor(props: IGetPairChartDataRequest) {
        this._placementName = props.placementName.trim();
        this._symbol = props.symbol.trim();
    }

    serialize(): IGetPairChartDataRequest {
        const data: IGetPairChartDataRequest = {
            placementName: this._placementName,
            symbol: this._symbol,
        };
        return data;
    }

    toJSON(): IGetPairChartDataRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
