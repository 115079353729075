
import Vue from 'vue';

import { ISubscription, subscribe, unsubscribeChannel } from 'Store/v2/PublicSocketData';
import MarketDataApi from 'Apis/MarketData';
import SpotTradesRequest from 'Entities/publicPresenter/SpotTradesRequest';
import Trade from 'Entities/publicPresenter/Trade';

interface IInitPayload {
    placement: string,
    pair: string,
}

export interface ITradeRecord {
    amount: string,
    date: string,
    side: string,
    price: string,
    fullTimestamp: string,
}

export default Vue.extend({
    data() {
        return {
            trades: [] as Trade[],
            subscriptionIndex: null as ISubscription | null,
            activePair: '',
        };
    },
    methods: {
        SET_TRADES(trade: Trade) {
            this.trades = [trade, ...this.trades.slice(0, 19)];
        },
        SET_SUBSCRIPTION_INDEX(index: ISubscription) {
            this.subscriptionIndex = index;
        },
        SET_TRADES_SNAPSHOT(payload: Trade[]) {
            this.trades = [];
            if (payload.length) {
                const temp = payload.sort((a, b) => {
                    return b.timestamp! - a.timestamp!;
                });
                this.trades = temp.length > 20 ? temp.slice(0, 20) : payload;
            }
        },
        async init(placementAndPairData: IInitPayload) {
            const { placement, pair } = placementAndPairData;
            this.activePair = '';

            if (!placement || !pair) {
                return;
            }

            this.SET_TRADES_SNAPSHOT([]);

            if (this.subscriptionIndex !== null) {
                await this.$store.dispatch(unsubscribeChannel(this.subscriptionIndex));
            }
            const index = await this.$store.dispatch(subscribe({
                channel: `trades:${this.$store.state.Placements.placementNamesToPlacementTags.get(placement.toUpperCase())}-${pair}`,
                callbacks: {
                    subscribe: async () => {
                        try {
                            const { data: res } = await MarketDataApi.getPublicSpotTrades(new SpotTradesRequest({
                                placementName: placement,
                                symbol: pair,
                            }));
                            if (Array.isArray(res)) {
                                this.activePair = pair;
                                this.SET_TRADES_SNAPSHOT(res);
                            }
                        } catch {
                            // code crushed because of network error
                        }
                    },
                    publish: ({ data }: { data }) => {
                        if (data.type === 'snapshot') {
                            this.SET_TRADES_SNAPSHOT(data.trades.map((t) => new Trade(t)));
                        } else if (this.trades.length > 0) {
                            data.trades.forEach((t) => {
                                this.SET_TRADES(new Trade(t));
                            });
                        }
                    },
                },
            }));

            this.SET_SUBSCRIPTION_INDEX(index);
        },
    },
});
