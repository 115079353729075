<template>
    <div class="module">
        <BlockHeader
            class="draggable"
            title="Price chart"
        >
            <div :id="moduleId" :class="s.selectResolution__container">
                <div resolution="40320" :class="s.selectResolution__item">4W</div>
                <div resolution="20160" :class="s.selectResolution__item">2W</div>
                <div resolution="10080" :class="s.selectResolution__item">1W</div>
                <div resolution="1440" :class="s.selectResolution__item">1D</div>
                <div resolution="720" :class="s.selectResolution__item">12h</div>
                <div resolution="480" :class="s.selectResolution__item">8h</div>
                <div resolution="240" :class="s.selectResolution__item">4h</div>
                <div resolution="60" :class="[s.selectResolution__item, s.active]">1h</div>
                <div resolution="30" :class="s.selectResolution__item">30m</div>
                <div resolution="15" :class="s.selectResolution__item">15m</div>
                <div resolution="5" :class="s.selectResolution__item">5m</div>
                <div resolution="1" :class="s.selectResolution__item">1m</div>
            </div>
        </BlockHeader>
        <div :class="s.content">
            <TradingViewChart
                @update="setDefaultResolution"
                @resolution="setResolution($event)"
                :resolution="resolution"
                :pair="pair"
                :placement="placement"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { THEMES_NAMES } from 'Config/tradingView';
import BlockHeader from 'UI/BlockHeader.vue';

import TradingViewChart from './components/TradingViewChartNew.vue';

export default {
    name: 'PriceChart',
    components: {
        TradingViewChart,
        BlockHeader,
    },
    data() {
        return {
            resolution: '60',
            moduleId: 'id',
        };
    },
    props: {
        placement: {
            type: String,
        },
        pair: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        activeAccountColor() {
            if (!this.activeAccount) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            if (!this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        theme() {
            return this.$store.getters.isThemeDark ? THEMES_NAMES.DARK : THEMES_NAMES.LIGHT;
        },
    },
    methods: {
        setResolution(resolution) {
            const items = document.getElementById(this.moduleId).querySelectorAll(`.${this.s.selectResolution__item}`);
            items.forEach((itm) => {
                if (itm.getAttribute('resolution') === String(resolution)) {
                    itm.classList.add(this.s.active);
                    this.resolution = itm.getAttribute('resolution');
                } else {
                    itm.classList.remove(this.s.active);
                }
            });
        },
        setDefaultResolution() {
            const items = document.getElementById(this.moduleId).querySelectorAll(`.${this.s.selectResolution__item}`);
            items.forEach((itm) => {
                if (itm.innerHTML === '1h') {
                    itm.classList.add(this.s.active);
                    this.resolution = itm.getAttribute('resolution');
                } else {
                    itm.classList.remove(this.s.active);
                }
            });
        },
    },
    created() {
        // generate module id
        this.moduleId = `id${Math.round(Math.random() * 100000)}`;
    },
    mounted() {
        try {
            const items = document.getElementById(this.moduleId).querySelectorAll(`.${this.s.selectResolution__item}`);
            items.forEach((item) => {
                item.addEventListener('click', () => {
                    items.forEach((itm) => {
                        itm.classList.remove(this.s.active);
                    });
                    item.classList.add(this.s.active);
                    this.resolution = item.getAttribute('resolution');
                });
            });
        } catch {
            // code crushed because of getElementById returns null
        }
    },
};
</script>

<style lang="postcss" module="s">
.selectResolution__container {
    display: flex;
    flex-direction: row;
    column-gap: var(--m-s);
    justify-content: center;
    padding: 5px;
    background-color: #F1F2F5;
    border-radius: 100px;
    width: fit-content;
}

.selectResolution__item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 14%;
    text-align: center;
    cursor: pointer;
    color: rgba(35, 36, 77, 0.5);
}

.selectResolution__item.active {
    color: var(--cl-violet);
    font-weight: var(--fw-extrabold);
}
.content {
    width: 100%;
    flex-grow: 1;
}

[data-theme="dark"] {
    .selectResolution__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 5px;
        background-color: #23232A;
        border-radius: 100px;
        width: 360px;
    }

    .selectResolution__item {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        width: 14%;
        text-align: center;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.5);
    }

    .selectResolution__item.active {
        color: var(--cl-violet);
        font-weight: var(--fw-extrabold);
    }
}
</style>
