<template>
    <div :class="s.mainContainer" :id="uniqueComponentIdentifier">
        <div v-if="isLoading" :class="s.loaderContainer">
            <Preloader />
        </div>
        <div :class="s.col">
            <div
                :class="[s.row, s.relative]"
                v-for="(ask, index) in asks"
                :key="index"
            >
                <span class="price" :class="theme.orderbook.priceText">
                    {{ ask.price }}
                </span>
                <div :class="s.w100">
                    <span :class="theme.orderbook.askVolume" :style="`width: ${ask.volumePercent}%;`"></span>
                </div>
                <span :class="theme.orderbook.askVolumeText">
                    {{ ask.volume.getSeparatedDigits() }}
                </span>
            </div>
        </div>
        <div
            :class="[theme.orderbook.marketPriceText, { [theme.orderbook.red]: tradesShift < 0, [theme.orderbook.green]: tradesShift > 0 }]"
             v-if="tradesShift !== null && !isLoading"
        >
            <Icon
                v-if="tradesShift !== null"
                icon="triangle"
                :class="[s.triangle, { [s.rotated]: tradesShift < 0, [s.zero]: tradesShift === 0 }]"
                small
            />
            <span>
                {{ Number(marketPrice).toFixed(pricePrecision).getSeparatedDigits() }}
            </span>
        </div>
        <div :class="s.col">
            <div
                :class="[s.row, s.relative]"
                v-for="(bid, index) in bids"
                :key="index"
            >
                <span class="price" :class="theme.orderbook.priceText">
                    {{ bid.price }}
                </span>
                <div :class="s.w100">
                    <span :class="theme.orderbook.bidVolume" :style="`width: ${bid.volumePercent}%;`"></span>
                </div>
                <span :class="theme.orderbook.bidVolumeText">
                    {{ bid.volume.getSeparatedDigits() }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { nanoid } from 'nanoid';

import { PriceChangeStatuses } from 'Models/tradeHistory';
import theme from 'Theme';
import Icon from 'UI/Icon.vue';

export default {
    name: 'PriceLadderChart',
    components: {
        Icon,
    },
    props: {
        bids: {
            type: Array,
            required: true,
        },
        asks: {
            type: Array,
            required: true,
        },
        pricePrecision: {
            type: Number,
            default: 6,
        },
        marketPrice: {
            type: Number,
            default: 0,
        },
        lastMarketPrice: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            PriceChangeStatuses,
            uniqueComponentIdentifier: '',
            theme,
        };
    },
    computed: {
        ...mapGetters({
            tradingPriceChange: 'TradeHistory/tradingPriceChange',
            activeTerminalAssetPairPrecisionAmount: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_AMOUNT',
        }),
        tradesShift() {
            if (this.marketPrice) {
                return this.marketPrice - this.lastMarketPrice;
            }
            return null;
        },
        isLoading() {
            return this.asks.length === 0 && this.bids.length === 0;
        },
    },
    methods: {
        updateWidth() {
            try {
                const pricesArray = document.querySelectorAll(`#${this.uniqueComponentIdentifier} .price`);
                requestAnimationFrame(() => {
                    if (pricesArray[0]) {
                        pricesArray[0].style.width = 'auto';
                    }
                    requestAnimationFrame(() => {
                        for (let i = 0; i < pricesArray.length; i += 1) {
                            if (pricesArray[i]) {
                                pricesArray[i].style.width = `${pricesArray[0].offsetWidth}px`;
                            }
                        }
                    });
                });
            } catch {
                // code crushed here because of querySelector returns null
            }
        },
    },
    mounted() {
        this.uniqueComponentIdentifier = `id${nanoid(8)}`;
    },
    watch: {
        bids() {
            this.updateWidth();
        },
        asks() {
            this.updateWidth();
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 4px;
    width: 100%;
    height: 100%;
}
.col {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    justify-content: space-between;
    height: 100%;
}
.mainContainer {
    height: calc(100% - 37px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--m-xs) var(--m-m);
    position: relative;

    & .loaderContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-block-color);
    }
}
.relative {
    position: relative;
}
.w100 {
    width: 100%;
}
.triangle {
    transition: transform var(--transition);
    color: var(--cl-green);
    &.rotated {
        transform: rotate(180deg);
        color: var(--cl-red);
    }
    &.zero {
        transform: rotate(90deg);
        color: var(--cl-black);
    }
}
</style>
