export const UP_COLOR = '#15B075';
const DOWN_COLOR = '#C92C3E';

export const THEMES_NAMES = {
    DARK: 'Dark',
    LIGHT: 'Light',
};

export const lightOverrides = {
    'mainSeriesProperties.candleStyle.upColor': UP_COLOR,
    'mainSeriesProperties.candleStyle.downColor': DOWN_COLOR,
    'mainSeriesProperties.candleStyle.borderUpColor': UP_COLOR,
    'mainSeriesProperties.candleStyle.borderDownColor': DOWN_COLOR,
    'mainSeriesProperties.candleStyle.wickUpColor': UP_COLOR,
    'mainSeriesProperties.candleStyle.wickDownColor': DOWN_COLOR,

    'scalesProperties.lineColor': '#F5F6FA',
    'scalesProperties.textColor': 'rgba(35, 36, 77, 0.5)',

    'paneProperties.background': 'white',
    'paneProperties.vertGridProperties.color': 'rgba(255, 255, 255, 0)',
    'paneProperties.horzGridProperties.color': 'rgba(0, 0, 0, 0.05)',

    'mainSeriesProperties.prevClosePriceLineColor': '#23244D',

    'paneProperties.crossHairProperties.style': 0,
    'paneProperties.vertGridProperties.style': 0,
    'paneProperties.horzGridProperties.style': 0,
    'paneProperties.crossHairProperties.color': '#23244D',

    'mainSeriesProperties.hollowCandleStyle.wickColor': '#23244D',

    editorFontsList: 'NunitoSans',
};

export const darkOverrides = {
    'mainSeriesProperties.candleStyle.upColor': UP_COLOR,
    'mainSeriesProperties.candleStyle.downColor': DOWN_COLOR,
    'mainSeriesProperties.candleStyle.borderUpColor': UP_COLOR,
    'mainSeriesProperties.candleStyle.borderDownColor': DOWN_COLOR,
    'mainSeriesProperties.candleStyle.wickUpColor': UP_COLOR,
    'mainSeriesProperties.candleStyle.wickDownColor': DOWN_COLOR,

    'scalesProperties.lineColor': '#23232A',
    'scalesProperties.backgroundColor': '#23232A',
    'scalesProperties.textColor': '#ffffff',

    'paneProperties.background': '#2C2C36',
    'paneProperties.backgroundType': 'solid',

    'paneProperties.vertGridProperties.color': 'rgba(255, 255, 255, 0)',
    'paneProperties.horzGridProperties.color': 'rgba(255, 255, 255, 0.05)',

    'mainSeriesProperties.prevClosePriceLineColor': '#23244D',

    'paneProperties.crossHairProperties.style': 0,
    'paneProperties.vertGridProperties.style': 0,
    'paneProperties.horzGridProperties.style': 0,
    'paneProperties.crossHairProperties.color': '#ffffff',

    'mainSeriesProperties.hollowCandleStyle.wickColor': '#23244D',

    editorFontsList: 'NunitoSans',
};
