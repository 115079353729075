<template>
    <div class="module trade-history">
        <BlockHeader
            title="Trade History"
            class="draggable"
        />
        <div :class="s.scroll">
            <TradeHistoryTable
                v-if="trades.length"
                :current-asset-pair="pair || ''"
                :current-placement="placement || ''"
                :trades="trades"
            />
            <Preloader
                v-else
                is-absolute-centered
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BlockHeader from 'UI/BlockHeader.vue';
import { unsubscribeChannel } from 'Store/v2/PublicSocketData';

import TradeHistoryTable from './components/TradeHistory.Table.vue';
import TradeHistoryData from './TradeHistory.Data.vue';

export default {
    name: 'TradeHistory',
    mixins: [TradeHistoryData],
    components: {
        TradeHistoryTable,
        BlockHeader,
    },
    props: {
        placement: {
            type: String,
        },
        pair: {
            type: String,
        },
    },
    data() {
        return {
            lastPlacementName: '',
            lastPairSymbol: '',
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
    },
    watch: {
        async placement(value) {
            if (value && this.pair && (value !== this.lastPlacementName || this.pair !== this.lastPairSymbol)) {
                this.lastPlacementName = value;
                this.lastPairSymbol = this.pair;
                await this.init({ placement: this.placement, pair: this.pair });
            }
        },
        async pair(value) {
            if (value && this.placement && (value !== this.lastPairSymbol || this.placement !== this.lastPlacementName)) {
                this.lastPlacementName = this.placement;
                this.lastPairSymbol = value;
                await this.init({ placement: this.placement, pair: this.pair });
            }
        },
    },
    async mounted() {
        if (this.placement && this.pair) {
            this.lastPlacementName = this.placement;
            this.lastPairSymbol = this.pair;
            await this.init({ placement: this.placement, pair: this.pair });
        }
    },
    async beforeDestroy() {
        if (this.subscriptionIndex !== null) {
            await this.$store.dispatch(unsubscribeChannel(this.subscriptionIndex));
            this.subscriptionIndex = null;
            this.lastPlacementName = '';
            this.lastPairSymbol = '';
        }
    },
};
</script>

<style lang="postcss" module="s">
.scroll {
    overflow-y: scroll;
}
</style>
