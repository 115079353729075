
import mixins from 'vue-typed-mixins';

import BlockHeader from 'UI/BlockHeader.vue';
import { unsubscribeChannel } from 'Store/v2/PublicSocketData';
import theme from 'Theme';
import PlacementIcon from 'Common/PlacementIcon.vue';

import Ladder from './components/OrderBook.Ladder.vue';
import OrderBookData from './OrderBook.Data.vue';

interface Computed {
    newAggregationsIndex: number;
    newIsCumulative: boolean;
    newIsFulfilledLadder: boolean;
    pricePrecision: number;
}

interface Props {
    placement: string;
    pair: string;
}

interface Methods {
    onSelect: (data: { index: number }) => void;
}

export default mixins(OrderBookData).extend<unknown, Methods, Computed, Props>({
    name: 'OrderBook',
    mixins: [OrderBookData],
    components: {
        BlockHeader,
        Ladder,
        PlacementIcon,
    },
    props: {
        placement: {
            type: String,
        },
        pair: {
            type: String,
        },
    },
    methods: {
        onSelect({ index }) {
            this.newAggregationsIndex = index;
        },
    },
    data() {
        return {
            lastPlacementName: '',
            lastPairSymbol: '',
            theme,
        };
    },
    computed: {
        newAggregationsIndex: {
            get() {
                return this.ui.activeAggregationIndex;
            },
            set(newVal) {
                this.ui.activeAggregationIndex = newVal;
                this.UPDATE_ORDER_BOOK();
            },
        },
        newIsCumulative: {
            get() {
                return this.ui.isCumulative;
            },
            set(newVal) {
                this.ui.isCumulative = newVal;
                this.UPDATE_ORDER_BOOK();
            },
        },
        newIsFulfilledLadder() {
            return this.sequence !== 0;
        },
        pricePrecision() {
            return this.$store.state.AssetPairs.workspaceSpotAssetPairs
                .get(this.placement?.toUpperCase() ?? '')
                ?.get(this.pair?.toUpperCase() ?? '')
                ?.placementPrecisionPrice
                ?? 8;
        },
    },
    watch: {
        async pair(value) {
            if (value && this.placement && (this.lastPlacementName !== this.placement || this.lastPairSymbol !== value)) {
                this.lastPairSymbol = value;
                this.lastPlacementName = this.placement;
                await this.init({ placement: this.placement, pair: this.pair });
            }
        },
        async placement(value) {
            if (value && this.pair && (this.lastPlacementName !== value || this.lastPairSymbol !== this.pair)) {
                this.lastPairSymbol = this.pair;
                this.lastPlacementName = value;
                await this.init({ placement: this.placement, pair: this.pair });
            }
        },
    },
    async mounted() {
        if (this.pair && this.placement) {
            this.lastPairSymbol = this.pair;
            this.lastPlacementName = this.placement;
            await this.init({ placement: this.placement, pair: this.pair });
        }
    },
    async beforeDestroy() {
        if (this.tradesSubscriptionIndex !== null) {
            await this.$store.dispatch(unsubscribeChannel(this.tradesSubscriptionIndex));
            this.tradesSubscriptionIndex = null;
        }
        if (this.subscriptionIndex !== null) {
            await this.$store.dispatch(unsubscribeChannel(this.subscriptionIndex));
            this.subscriptionIndex = null;
        }
        this.lastPairSymbol = '';
        this.lastPlacementName = '';
    },
});
