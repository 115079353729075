// This file was autogenerated. Please do not change.
export interface ISavePairChartDataRequest {
    chartData: undefined;
    placementName: string;
    symbol: string;
}

export default class SavePairChartDataRequest {
    readonly _chartData: undefined;

    get chartData(): undefined {
        return this._chartData;
    }

    readonly _placementName: string;

    get placementName(): string {
        return this._placementName;
    }

    readonly _symbol: string;

    get symbol(): string {
        return this._symbol;
    }

    constructor(props: ISavePairChartDataRequest) {
        this._chartData = props.chartData;
        this._placementName = props.placementName.trim();
        this._symbol = props.symbol.trim();
    }

    serialize(): ISavePairChartDataRequest {
        const data: ISavePairChartDataRequest = {
            chartData: this._chartData,
            placementName: this._placementName,
            symbol: this._symbol,
        };
        return data;
    }

    toJSON(): ISavePairChartDataRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
