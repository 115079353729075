
import Vue from 'vue';

import FuturesPosition from 'Entities/privatePresenter/FuturesPosition';
import Icon from 'UI/Icon.vue';
import MoneyInput from 'Control/MoneyInput.vue';
import Button from 'Control/Button.vue';
import { IPlaceOrderPayload, placeOrder } from 'Store/v2/TakeProfitStopLossModal';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import Checkbox from 'Control/Checkbox.vue';
import FuturesOrderPresenter from 'Entities/privatePresenter/FuturesOrderPresenter';
import FuturesApi from 'Apis/Futures';
import CancelOrderRequest from 'Entities/futuresOrderRegistrar/CancelOrderRequest';
import { composedPath } from 'Lib/utils/eventPathChecker';

/*
* emits
* confirm: (data: IPlaceOrderPayload) => void;
* cancel: () => void;
* */

interface Data {
    takeProfitTriggerPrice: number;
    takeProfitPrice: number;
    stopLossTriggerPrice: number;
    stopLossPrice: number;
    orderTypeIndex: number;
    isTakeProfitSelected: boolean;
    isStopLossSelected: boolean;
    hasTakeProfitError: boolean;
    takeProfitErrorText: string;
    hasStopLossError: boolean;
    stopLossErrorText: string;
    isModalOpened: boolean;
}

interface Methods {
    onTakeProfitTriggerPriceInput: (data: string | number) => void;
    onTakeProfitPriceInput: (data: string | number) => void;
    onStopLossTriggerPriceInput: (data: string | number) => void;
    onStopLossPriceInput: (data: string | number) => void;
    placeOrder: () => void;
    closeModal: (isCancel?: boolean) => void;
    onTakeProfitCheckboxToggle: () => void;
    onStopLossCheckboxToggle: () => void;
    cancelTakeProfit: () => void;
    cancelStopLoss: () => void;
    validateTakeProfit: () => boolean;
    validateStopLoss: () => boolean;
    presetStartValues: (data: IPlaceOrderPayload) => void;
    escapeListener: (data: any) => void;
    clickOutsideListener: (data: any) => void;
}

interface Computed {
    position: FuturesPosition | null;
    contractSymbol: string;
    leverage: number;
    isLong: boolean;
    entryPrice: number;
    markPrice: number;
    quoteAssetSymbol: string;
    takeProfitPnl: number;
    stopLossPnl: number;
    takeProfitPnlPercent: string;
    stopLossPnlPercent: string;
    activeTakeProfitOrder: null | FuturesOrderPresenter;
    activeStopLossOrder: null | FuturesOrderPresenter;
    modalName: string;
    pricePrecision: number;
    quoteAssetPrecision: number;
}

interface Props {
    isTrading: boolean;
    uniqueId: string;
    marketPrice?: number;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        Checkbox,
        HeaderSwitcher,
        Icon,
        MoneyInput,
        Button,
    },
    props: {
        isTrading: {
            type: Boolean,
            default: false,
        },
        uniqueId: {
            type: String,
            default: '',
        },
        marketPrice: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            orderTypeIndex: 0,
            isTakeProfitSelected: false,
            isStopLossSelected: false,
            takeProfitTriggerPrice: 0,
            takeProfitPrice: 0,
            stopLossTriggerPrice: 0,
            stopLossPrice: 0,
            hasTakeProfitError: false,
            takeProfitErrorText: '',
            hasStopLossError: false,
            stopLossErrorText: '',
            isModalOpened: false,
        };
    },
    computed: {
        modalName() {
            return this.isTrading ? `tpsl${this.uniqueId}` : 'TakeProfitStopLossModal';
        },
        position() {
            return this.$store.state.TakeProfitStopLossModal.position;
        },
        quoteAssetPrecision() {
            return this.$store.getters['AssetPairs/GET_ASSET_PAIRS'].find((pair) => pair.symbol === this.contractSymbol)?.placementPrecisionPrice ?? 8;
        },
        contractSymbol() {
            return this.position?.contractSymbol ?? '';
        },
        leverage() {
            return Number(this.position?.leverage) ?? 1;
        },
        isLong() {
            return (this.position?.side ?? 'long').toLowerCase() === 'long';
        },
        entryPrice() {
            return this.position?.entryPrice ?? 0;
        },
        markPrice() {
            return this.isTrading ? (Number(this.marketPrice) ?? 0) : (Number(this.position?.markPrice) ?? 0);
        },
        quoteAssetSymbol() {
            return this.contractSymbol?.split('/')[1] ?? '';
        },
        takeProfitPnl() {
            if (this.activeTakeProfitOrder) {
                if (this.activeTakeProfitOrder.price) {
                    return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.activeTakeProfitOrder.price ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
                }
                return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.activeTakeProfitOrder.triggerPrice ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
            }
            if (this.orderTypeIndex === 1) {
                return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.takeProfitPrice ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
            }
            return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.takeProfitTriggerPrice ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
        },
        stopLossPnl() {
            if (this.activeStopLossOrder) {
                if (this.activeStopLossOrder.price) {
                    return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.activeStopLossOrder.price ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
                }
                return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.activeStopLossOrder.triggerPrice ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
            }
            if (this.orderTypeIndex === 1) {
                return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.stopLossPrice ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
            }
            return (this.isLong ? 1 : -1) * ((((this.position?.quoteQuantity ?? 0) * (this.stopLossTriggerPrice ?? 0)) / (this.position?.entryPrice ?? 1)) - (this.position?.quoteQuantity ?? 0));
        },
        takeProfitPnlPercent() {
            return `(${((this.takeProfitPnl / (this.position?.margin ?? 1)) * 100).toFixed(2).noExponents()}%)`;
        },
        stopLossPnlPercent() {
            return `(${((this.stopLossPnl / (this.position?.margin ?? 1)) * 100).toFixed(2).noExponents()}%)`;
        },
        activeTakeProfitOrder() {
            return this.$store.state.TakeProfitStopLossModal.activeTakeProfitOrder;
        },
        activeStopLossOrder() {
            return this.$store.state.TakeProfitStopLossModal.activeStopLossOrder;
        },
        pricePrecision() {
            const assetPair = this.$store.state.AssetPairs.spotAssetPairs.get(this.$store.state.AssetPairs.activeTerminalAssetPairId ?? 0);
            return Number(assetPair?.placementPrecisionPrice) ?? 4;
        },
    },
    methods: {
        presetStartValues(payload: IPlaceOrderPayload) {
            if (payload.orderType === 'Limit') {
                this.orderTypeIndex = 1;
            } else {
                this.orderTypeIndex = 0;
            }
            if (payload.takeProfitTriggerPrice) {
                this.isTakeProfitSelected = true;
                this.takeProfitTriggerPrice = payload.takeProfitTriggerPrice;
                if (payload.takeProfitPrice) {
                    this.takeProfitPrice = payload.takeProfitPrice;
                }
            }
            if (payload.stopLossTriggerPrice) {
                this.isStopLossSelected = true;
                this.stopLossTriggerPrice = payload.stopLossTriggerPrice;
                if (payload.stopLossPrice) {
                    this.stopLossPrice = payload.stopLossPrice;
                }
            }
        },
        onTakeProfitCheckboxToggle() {
            this.isTakeProfitSelected = !this.isTakeProfitSelected;
            this.onTakeProfitTriggerPriceInput(0);
            this.onTakeProfitPriceInput(0);
        },
        onStopLossCheckboxToggle() {
            this.isStopLossSelected = !this.isStopLossSelected;
            this.onStopLossTriggerPriceInput(0);
            this.onStopLossPriceInput(0);
        },
        onTakeProfitTriggerPriceInput(value) {
            this.takeProfitTriggerPrice = Number(value);
            this.hasTakeProfitError = false;
            this.takeProfitErrorText = '';
        },
        onTakeProfitPriceInput(value) {
            this.takeProfitPrice = Number(value);
        },
        onStopLossTriggerPriceInput(value) {
            this.stopLossTriggerPrice = Number(value);
            this.hasStopLossError = false;
            this.stopLossErrorText = '';
        },
        onStopLossPriceInput(value) {
            this.stopLossPrice = Number(value);
        },
        async placeOrder() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                if (this.orderTypeIndex === 1) {
                    if (this.isTakeProfitSelected && this.isStopLossSelected) {
                        const stopLossValidation = this.validateStopLoss();
                        const takeProfitValidation = this.validateTakeProfit();
                        if (!stopLossValidation || !takeProfitValidation) {
                            return;
                        }
                        const payload: IPlaceOrderPayload = {
                            orderType: 'Limit',
                            takeProfitTriggerPrice: this.takeProfitTriggerPrice,
                            takeProfitPrice: this.takeProfitPrice ?? 0,
                            stopLossTriggerPrice: this.stopLossTriggerPrice,
                            stopLossPrice: this.stopLossPrice ?? 0,
                        };
                        if (this.isTrading) {
                            this.$emit('confirm', payload);
                        } else {
                            await this.$store.dispatch(placeOrder(payload));
                        }
                    } else if (!this.isTakeProfitSelected && this.isStopLossSelected) {
                        const stopLossValidation = this.validateStopLoss();
                        if (!stopLossValidation) {
                            return;
                        }
                        const payload: IPlaceOrderPayload = {
                            orderType: 'Limit',
                            stopLossTriggerPrice: this.stopLossTriggerPrice,
                            stopLossPrice: this.stopLossPrice ?? 0,
                        };
                        if (this.isTrading) {
                            this.$emit('confirm', payload);
                        } else {
                            await this.$store.dispatch(placeOrder(payload));
                        }
                    } else if (this.isTakeProfitSelected && !this.isStopLossSelected) {
                        const takeProfitValidation = this.validateTakeProfit();
                        if (!takeProfitValidation) {
                            return;
                        }
                        const payload: IPlaceOrderPayload = {
                            orderType: 'Limit',
                            takeProfitTriggerPrice: this.takeProfitTriggerPrice,
                            takeProfitPrice: this.takeProfitPrice ?? 0,
                        };
                        if (this.isTrading) {
                            this.$emit('confirm', payload);
                        } else {
                            await this.$store.dispatch(placeOrder(payload));
                        }
                    } else {
                        await this.$store.dispatch('Notificator/showErrorNotification', 'Enter trigger price for stop loss order or for take profit order');
                    }
                    return;
                }
                if (this.isTakeProfitSelected && this.isStopLossSelected) {
                    const stopLossValidation = this.validateStopLoss();
                    const takeProfitValidation = this.validateTakeProfit();
                    if (!stopLossValidation || !takeProfitValidation) {
                        return;
                    }
                    const payload: IPlaceOrderPayload = {
                        orderType: 'Market',
                        takeProfitTriggerPrice: this.takeProfitTriggerPrice,
                        stopLossTriggerPrice: this.stopLossTriggerPrice,
                    };
                    if (this.isTrading) {
                        this.$emit('confirm', payload);
                    } else {
                        await this.$store.dispatch(placeOrder(payload));
                    }
                } else if (!this.isTakeProfitSelected && this.isStopLossSelected) {
                    const stopLossValidation = this.validateStopLoss();
                    if (!stopLossValidation) {
                        return;
                    }
                    const payload: IPlaceOrderPayload = {
                        orderType: 'Market',
                        stopLossTriggerPrice: this.stopLossTriggerPrice,
                    };
                    if (this.isTrading) {
                        this.$emit('confirm', payload);
                    } else {
                        await this.$store.dispatch(placeOrder(payload));
                    }
                } else if (this.isTakeProfitSelected && !this.isStopLossSelected) {
                    const takeProfitValidation = this.validateTakeProfit();
                    if (!takeProfitValidation) {
                        return;
                    }
                    const payload: IPlaceOrderPayload = {
                        orderType: 'Market',
                        takeProfitTriggerPrice: this.takeProfitTriggerPrice,
                    };
                    if (this.isTrading) {
                        this.$emit('confirm', payload);
                    } else {
                        await this.$store.dispatch(placeOrder(payload));
                    }
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Enter trigger price for stop loss order or for take profit order');
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error placing order(s)');
                }
            } finally {
                if (!this.hasStopLossError && !this.hasTakeProfitError) {
                    this.closeModal();
                }
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        closeModal(isCancel = false) {
            if (isCancel) {
                this.$emit('cancel');
            }
            this.$modal.hide(this.modalName);
            this.isTakeProfitSelected = false;
            this.isStopLossSelected = false;
            this.hasStopLossError = false;
            this.hasTakeProfitError = false;
            this.stopLossErrorText = '';
            this.takeProfitErrorText = '';
        },
        async cancelTakeProfit() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await FuturesApi.privateFuturesCancelOrder(new CancelOrderRequest({
                    id: this.activeTakeProfitOrder!.id,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error cancelling order');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async cancelStopLoss() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await FuturesApi.privateFuturesCancelOrder(new CancelOrderRequest({
                    id: this.activeStopLossOrder!.id,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error cancelling order');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        validateTakeProfit() {
            if (this.position && this.position.side.toLowerCase() === 'long') {
                if (this.takeProfitTriggerPrice <= Number(this.markPrice)) {
                    this.takeProfitErrorText = `Value must be greater then ${Number(this.markPrice).floor(8)}`;
                    this.hasTakeProfitError = true;
                    return false;
                }
            } else if (this.position && this.takeProfitTriggerPrice >= Number(this.markPrice)) {
                this.takeProfitErrorText = `Value must be lower then ${Number(this.markPrice).floor(8)}`;
                this.hasTakeProfitError = true;
                return false;
            }
            if (this.takeProfitTriggerPrice === 0) {
                this.takeProfitErrorText = 'Value must be greater then 0';
                this.hasTakeProfitError = true;
                return false;
            }
            return true;
        },
        validateStopLoss() {
            if (this.position && this.position.side.toLowerCase() === 'long') {
                // if (this.stopLossTriggerPrice <= this.position.liquidationPrice) {
                //     this.stopLossErrorText = `Value must be greater then ${this.position.liquidationPrice.floor(8)}`;
                //     this.hasStopLossError = true;
                //     return false;
                // }
                if (this.stopLossTriggerPrice >= Number(this.markPrice)) {
                    this.stopLossErrorText = `Value must be lower then ${Number(this.markPrice).floor(8)}`;
                    this.hasStopLossError = true;
                    return false;
                }
            } else {
                // if (this.position && this.stopLossTriggerPrice >= this.position.liquidationPrice) {
                //     this.stopLossErrorText = `Value must be lower then ${this.position.liquidationPrice.floor(8)}`;
                //     this.hasStopLossError = true;
                //     return false;
                // }
                // eslint-disable-next-line no-lonely-if
                if (this.position && this.stopLossTriggerPrice <= Number(this.markPrice)) {
                    this.stopLossErrorText = `Value must be greater then ${Number(this.markPrice).floor(8)}`;
                    this.hasStopLossError = true;
                    return false;
                }
            }
            if (this.stopLossTriggerPrice === 0) {
                this.stopLossErrorText = 'Value must be greater then 0';
                this.hasStopLossError = true;
                return false;
            }
            return true;
        },
        escapeListener(e) {
            // esc key code
            if (e.keyCode === 27 && this.isModalOpened) {
                this.closeModal(true);
            }
        },
        clickOutsideListener(e) {
            if (this.isModalOpened) {
                const path = composedPath(e.target);
                if (!path.some((node) => node.classList && Array.from(node.classList).includes('tpSlModal'))) {
                    this.closeModal(true);
                }
            }
        },
    },
    mounted() {
        document.addEventListener('keydown', (e) => this.escapeListener(e));
        document.addEventListener('mousedown', (e) => this.clickOutsideListener(e));
    },
    beforeDestroy() {
        document.removeEventListener('keydown', (e) => this.escapeListener(e));
        document.removeEventListener('mousedown', (e) => this.clickOutsideListener(e));
    },
    watch: {
        orderTypeIndex() {
            this.isStopLossSelected = false;
            this.isTakeProfitSelected = false;
        },
    },
});
