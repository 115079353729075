import { render, staticRenderFns } from "./CryptoLimit.vue?vue&type=template&id=5890d2c4&"
import script from "./CryptoLimit.vue?vue&type=script&lang=js&"
export * from "./CryptoLimit.vue?vue&type=script&lang=js&"
import style0 from "./CryptoLimit.vue?vue&type=style&index=0&id=5890d2c4&prod&lang=postcss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports