import { mapGetters } from 'vuex';

/**
 * @type {Vue.ComponentOptions}
 */
export default {
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            asset: 'Assets/GET_ASSET_BY_SYMBOL',
            quoteAssetSymbol: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL',
            assets: 'Assets/GET_ASSETS',

            marketPrice: 'OrderBook/marketPrice',
            getQuote: 'Assets/GET_QUOTE',
        }),
        orderValue() {
            const quoteAsset = this.assets.find((a) => a.symbol === this.quoteAssetSymbol);
            const quoteRate = quoteAsset && this.$store.state.Assets.quotations.has(quoteAsset.symbol) ? this.$store.state.Assets.quotations.get(quoteAsset.symbol)[this.quotationAssetSymbol] : 0;
            return this.total * quoteRate;
        },
    } as any,
};
