export function maxPrecision(precision) {
    return function maxPrecisionValidator(value) {
        return (+value).getPrecision() <= precision;
    };
}

export function divisible(number) {
    return function divisibleValidator(value) {
        return (+number).isAliquotOf(+value);
    };
}
