<template>
    <div class="module choose-placement">
        <BlockHeader
            title="Choose Placement"
            :with-search="true"
            @on-search="onSearch"
        />
        <div :class="s.scroll">
            <table
                v-if="linkedPlacements && linkedPlacements.length"
                :class="[theme.table.table, s.w100]"
            >
                <thead>
                <tr>
                    <th :class="[theme.table.headerText, theme.table.alignLeft]">connected placements</th>
                    <th :class="[theme.table.headerText, theme.table.alignLeft]"></th>
                    <th :class="[theme.table.headerText, theme.table.alignCenter]">volume</th>
                    <th :class="[theme.table.headerText, theme.table.alignRight]">Coins / Pairs</th>
                </tr>
                </thead>
                <tbody>
                <PlacementRow
                    v-for="placement in linkedPlacements"
                    :key="placement.id"
                    :placement="placement"
                />
                </tbody>
            </table>
            <table
                v-if="unlinkedPlacements && unlinkedPlacements.length"
                :class="[theme.table.table, s.w100]"
            >
                <thead>
                <tr>
                    <th
                        @click="showUnlinkedPlacements = !showUnlinkedPlacements"
                        :class="[theme.table.headerText, theme.table.alignLeft, s.pointer]"
                    >
                        available placements
                        <Icon
                            icon="arrow_right"
                            :class="[s.arrow, { [s.rotated]: showUnlinkedPlacements }]"
                        />
                    </th>
                    <th :class="[theme.table.headerText, theme.table.alignLeft]"></th>
                    <th :class="[theme.table.headerText, theme.table.alignCenter]">volume</th>
                    <th :class="[theme.table.headerText, theme.table.alignRight]">Coins / Pairs</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="showUnlinkedPlacements">
                    <PlacementRow
                        v-for="placement in unlinkedPlacements"
                        :key="placement.id"
                        :placement="placement"
                    />
                </template>
                </tbody>
            </table>
            <NoData
                v-if="unlinkedPlacements.length === 0 && linkedPlacements.length === 0"
                :class="s.noData"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { RELEASED_PLATFORMS } from 'Store/modules/Placements';
import theme from 'Theme';
import BlockHeader from 'UI/BlockHeader.vue';
import Icon from 'UI/Icon.vue';
import NoData from 'UI/NoData.vue';

import PlacementRow from './ChoosePlacement/PlacementRow.vue';

export default {
    name: 'ChooseExchange',
    components: {
        NoData,
        PlacementRow,
        BlockHeader,
        Icon,
    },
    data() {
        return {
            searchString: '',
            theme,
            showUnlinkedPlacements: true,
        };
    },
    computed: {
        ...mapGetters({
            placements: 'Placements/placements',
        }),
        filteredPlacements() {
            return this.placements.filter((placement) => !!~placement.name.toLowerCase().indexOf(this.searchString.toLowerCase()) && placement.id !== this.$store.getters['Placements/platformPlacementId']);
        },
        availablePlacements() {
            // eslint-disable-next-line no-prototype-builtins
            return this.filteredPlacements.filter((p) => RELEASED_PLATFORMS.hasOwnProperty(p.name));
        },
        centralizedPlacements() {
            return this.availablePlacements.filter((p) => p.type.indexOf('decentralized') === -1);
        },
        linkedPlacements() {
            return this.centralizedPlacements
                .filter(({ name }) => (
                    this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](name)
                    || this.$store.getters['Accounts/isPlacementPending'](name)
                ));
        },
        unlinkedPlacements() {
            return this.centralizedPlacements
                .filter(({ name }) => !(
                    this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](name)
                    || this.$store.getters['Accounts/isPlacementPending'](name)
                ));
        },
    },
    methods: {
        onSearch(e) {
            this.searchString = e;
        },
    },
};
</script>

<style lang="postcss" module="s">
.w100 {
    width: 100%;
}
.scroll {
    overflow-y: scroll;
    padding: 0 var(--m-s) var(--m-s) var(--m-s);
}
.arrow {
    width: 16px;
    height: 16px;

    &.rotated {
        transform: rotate(90deg);
    }
}
.pointer {
    cursor: pointer;
}
.noData {
    padding-bottom: 40px;
}
</style>
